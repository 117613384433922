(($) => {

	"use strict";

	const tabs = (() => {
		const activeClass = 'active';
		const $tabsContainer = $('.tabs-container');
		const $tabsMenu = $tabsContainer.find('.tabs-menu__item');
		const $tabsContent = $tabsContainer.find('.tab-content');

		const toggleActiveElement = ($element) => {
			$element.addClass(activeClass);
			$element.siblings().removeClass(activeClass);
		};

		const setActiveTabContent = (tabIndex) => {
			const $activeTab = $tabsContent.eq(tabIndex);
			toggleActiveElement($activeTab);
		};

		const setActiveTab = (event) => {
			const $activeMenuItem = $(event.target);
			const tabIndex = $activeMenuItem.index() || 0;
			toggleActiveElement($activeMenuItem);
			setActiveTabContent(tabIndex);
		};

		const addEvents = () => {
			$tabsMenu.on('click', setActiveTab);
		};

		const init = () => {
			addEvents();
		};

		return {
			init,
		};

	})();

	const productTabs = (() => {

		const $tabsWrapper = $('.collateral-tabs');
		const tabsToggle = $('.toggle-tabs');
		const $tabsContainers = $tabsWrapper.find('.tab-container');
		const $tabsHeaders = $tabsWrapper.find('.tab');
		const currentClass = 'current';
		const $slider = $('.toggle-tabs');
		let carousel;
		let currentIndex;

		const mobileMax = 768;

		const getItems = () => {
			return tabsToggle.find('.owl-item').length ? tabsToggle.find('.owl-item') : tabsToggle.find('li');
		};

		const setCurrent = () => {
			let current = getItems().filter((i, el) => el.classList.contains(currentClass)).index();
			current = current > -1 ? current : 0;
			currentIndex = current;
			return current;
		};

		const toggleTab = (index) => {
			const items = getItems();
			const tab = items.eq(index);
			currentIndex = index;

			$tabsContainers.add($tabsHeaders).removeClass(currentClass);
			$(tab).siblings().add(tabsToggle.find('li')).removeClass(currentClass);

			$(tab).addClass(currentClass);
			$tabsContainers.eq(index).addClass(currentClass);
			$tabsHeaders.eq(index).addClass(currentClass);
		};

		const carouselOptions = {
			dots: false,
			nav: false,
			autoWidth: true,
			center: true,
			responsive: {
				0: {
					center: true
				},
				420: {
					autoWidth: false,
					items: 2
				},
				767: {
					autoWidth: false,
					items: getItems().length
				}
			},

			onInitialized: () => {
				$slider.on('click', '.owl-item', (e) => {
					const index = $(e.currentTarget).index() || 0;
					carousel.trigger('to.owl.carousel', [index]);
				});
			},
			onTranslate: e => {
				toggleTab(e.item.index);
			}
		};

		const onResize = () => {
			setCurrent();

			if (window.innerWidth > mobileMax) {
				$slider.trigger('destroy.owl.carousel');
				toggleTab(currentIndex);
			} else {
				carousel = $slider.owlCarousel(carouselOptions);
				carousel.trigger('to.owl.carousel', [currentIndex]);
			}
		};


		const init = () => {
			tabsToggle.on('click', 'li', (e) => {
				if (window.innerWidth > mobileMax) {
					toggleTab($(e.currentTarget).index());
				}
			});

			$(document).ready(function () {
				onResize();
			});

			window.addEventListener('resize', onResize);
		};

		return {
			init
		};

	})();

	productTabs.init();
	tabs.init();

})($j);
