(($) => {

	"use strict";

	const menu = (() => {

		const header = document.querySelector('#header'),
			$skipCart = $('.header-minicart'),
			skipAccount = header.querySelector('.skip-account'),
			navButton = header.querySelector('.skip-nav'),
			productsBtn = header.querySelector('.nav-products'),
			miniCart = header.querySelector('#header-cart'),
			body = document.body,
			menuSelector = '.nav-primary',
			openMenuClass = 'menu-open',
			openCartClass = 'minicart-open',
			openAccountClass = 'account-open',
			// menuScrolledClass = 'menu-scrolled',
			// menuScrolledOffset = 36,
			// openModalClass = 'modal-open',
			$menu = $(menuSelector),
			$categoryLinks = $(productsBtn).children('ul').children('li'),
			parentActiveClass = 'menu-active',
			itemCurrentClass = 'current',
			parentSelector = '.parent',
			mobileMenuMax = 1024,
			sidebarMenuSelector = '.block-layered-nav .block-content ul',
			$sidebarMenu = $(sidebarMenuSelector);

		let windowOffset = 0;

		const isMobile = () => {
			return window.innerWidth <= mobileMenuMax;
		};

		const toggleDocumentScroll = disable => {
			if (isMobile()) {
				if (disable) {
					document.ontouchmove = e => {
						const $target = $(e.target);
						if (!$target.parents('#header-cart').length || $target.find('.empty').length) {
							e.preventDefault();
						} else {
							return true;
						}
					};
				} else {
					document.ontouchmove = () => {
						return true;
					};
				}

			} else {
				document.ontouchmove = () => {
					return true;
				};
			}
		};

		const closeCart = () => {
			body.classList.remove(openCartClass);
		};

		const closeAccount = () => {
			body.classList.remove(openAccountClass);
		};

		const toggleCart = (e) => {
			e.preventDefault();
			miniCart.classList.toggle('skip-active');
			body.classList.toggle(openCartClass);
			let opened = body.classList.contains(openCartClass);
			if (opened) {
				windowOffset = window.pageYOffset;
			} else {
				window.scroll(0, windowOffset);
			}
			toggleDocumentScroll(body.classList.contains(openCartClass));
		};

		const toggleAccount = () => {
			miniCart.classList.toggle('skip-active');
			body.classList.toggle(openAccountClass);
			toggleDocumentScroll(body.classList.contains(openAccountClass));
		};

		const closeMenu = () => {
			body.classList.remove(openMenuClass);
			toggleDocumentScroll(false);
			closeAccount();
		};

		// const closePopup = () => {
		// 	body.classList.remove(openModalClass);
		// 	$('#mini-cart-popup').fadeOut();
		// };

		const closeAll = () => {
			closeMenu();
			closeAccount();
			closeCart();
			window.scroll(0, windowOffset);
			// closePopup();
		};

		const addOverlay = () => {
			let overlay = document.querySelector('.overlay');
			if (!overlay) {
				overlay = document.createElement('div');
				overlay.classList.add('overlay');
				overlay.addEventListener('click', closeAll);
				body.appendChild(overlay);
			}
		};

		const openMenu = () => {
			closeCart();
			addOverlay();
			closeAccount();
			body.classList.add(openMenuClass);
			windowOffset = window.pageYOffset;
			toggleDocumentScroll(true);

			if (!isMobile()) {
				const activeCategories = $categoryLinks.filter(`.${parentActiveClass}`);
				if (!activeCategories.length) {
					$categoryLinks[0].classList.add(parentActiveClass);
				}
			}
		};

		const openMenuOnLoad = () => {
			$sidebarMenu.find('.active').filter('.parent').addClass('menu-active');
		};

		const getMenuState = () => body.classList.contains(openMenuClass);
		const getAccountState = () => body.classList.contains(openAccountClass);

		const toggleMenu = () => {
			body.classList.contains(openMenuClass) ? closeMenu() : openMenu();
			window.scroll(0, windowOffset);
		};

		const isSidebar = ($menuItem) => {
			return $menuItem.parents('.sidebar').length;
		};

		const setParentHeight = ($parent) => {
			if ($parent.length) {
				const el = $parent[0],
					isActive = $parent.hasClass(parentActiveClass),
					rect = el.getBoundingClientRect(),
					elementOffset = rect.top,
					h = rect.height;

				if (isActive) {

					requestAnimationFrame(function () {
						el.style.height = window.innerHeight - elementOffset + 'px';
					});

					el.classList.add(itemCurrentClass);
					$parent.siblings().removeClass(itemCurrentClass);

					$parent.siblings().find(parentSelector).css({
						height: 'auto'
					});

					$parent.siblings().css({
						height: 'auto'
					});

					$parent.children('ul').css({
						height: window.innerHeight - elementOffset - h + 'px',
						maxHeight: window.innerHeight - elementOffset - h + 'px'
					});
				}

				else {
					el.style.height = 'auto';
					el.classList.remove(itemCurrentClass);
					$(el).closest('.level0').scrollTop(0);
				}
			}
		};

		const resetItemsHeight = () => {
			const $items = $(header).find(parentSelector);
			$items.each((i, item) => {
				if (item.style.height) {
					item.removeAttribute('style');
				}
			});
		};

		const toggleMenuItem = ($target) => {
			$target.siblings('li').removeClass(parentActiveClass);
			$target.find(parentSelector).removeClass(parentActiveClass);
			$target.toggleClass(parentActiveClass);

			if (isMobile() && !isSidebar($target)) {
				setParentHeight($target);
			}
		};

		const allowMobileClick = e => {
			const $target = $(e.target);
			if (isMobile()) {
				return $target.is('a') ? $target.not('.level-top').length : $target.closest('a').not('.level-top').length;
			}
			return false;
		};

		const isProductMenu = e => {
			const $target = $(e.target);
			return $target.closest('.nav-products').length;
		};

		const toggleItem = e => {
			const $target = $(e.target);
			const $parent = $target.closest('li').filter(parentSelector);
			const level = $target.parents('ul').length; // 0: .nav-primary, 1: main category, 2: subcategory, 3: product list link

			const linkItem = !($parent.length || (isProductMenu(e) && level < 3));

			if (linkItem || allowMobileClick(e)) {
				return;
			}

			if (isMobile() || isSidebar($parent) || (!$parent.hasClass(parentActiveClass) && level !== 2)) {
				e.stopImmediatePropagation();
				e.preventDefault();
				toggleMenuItem($parent);
			}

			if ($parent.hasClass('nav-products') && !isMobile()) {
				e.stopImmediatePropagation();
				e.preventDefault();
				toggleMenu();
			}

		};

		const outsideMenuClick = () => {
			$('.page-header').on('click', e => {
				const menuOpened = getMenuState();
				const isList = e.target.classList.contains('level0');
				const accoutOpened = getAccountState();

				if (menuOpened && !isList && !isMobile()) {
					closeMenu();
				}
				if (accoutOpened && !isMobile() && e.target.classList.contains('page-header')) {
					closeAccount();
				}
			});
		};

		// const onScroll = () => {
		// 	window.addEventListener('scroll', () => {
		// 		if (window.pageYOffset > menuScrolledOffset) {
		// 			document.body.classList.add(menuScrolledClass);
		// 		} else {
		// 			document.body.classList.remove(menuScrolledClass);
		// 		}
		// 	});
		// };

		const onResize = () => {
			window.addEventListener('resize', () => {
				if (!isMobile()) {
					toggleDocumentScroll(false);
					resetItemsHeight();
				}
			});
		};

		const addEvents = () => {
			onResize();
			//onScroll();
			navButton.addEventListener('click', toggleMenu);
			$menu.on('click', parentSelector, toggleItem);
			$sidebarMenu.on('click', parentSelector, toggleItem);
			$skipCart.on('click', '.skip-cart', toggleCart);
			skipAccount.addEventListener('click', toggleAccount);
			outsideMenuClick();
			openMenuOnLoad();
		};

		const init = () => {
			addOverlay();
			addEvents();
		};

		return {
			init,
			openMenu,
			closeMenu,
			getMenuState,
			isMobile
		};

	})();


	// const search = (() => {

	// 	const $search = $('.header-search'),
	// 		$searchInput = $search.find('input[type="search"]'),
	// 		$searchBtn = $search.find('.search-button'),
	// 		searchHiddenClass = 'search-hidden',
	// 		emptyText = $('#search_mini_form').data('tip') || '';

	// 	const searchTogglable = () => {
	// 		return window.innerWidth > 1024 && window.innerWidth < 1200;
	// 	};

	// 	const searchHasInput = () => {
	// 		return $searchInput.val().trim() !== emptyText && $searchInput.val().trim().length;
	// 	};

	// 	const toggleSearch = (e) => {
	// 		const toggle = searchTogglable();
	// 		const hasInput = searchHasInput();

	// 		if (toggle && !hasInput) {
	// 			e.preventDefault();
	// 			$search.toggleClass(searchHiddenClass);
	// 		}
	// 	};

	// 	const onResize = () => {
	// 		if (!searchTogglable()) {
	// 			$search.removeClass(searchHiddenClass);
	// 		}
	// 		else {
	// 			$search.addClass(searchHiddenClass);
	// 		}
	// 	};

	// 	const addEvents = () => {
	// 		$searchBtn.on('click', toggleSearch);
	// 		onResize();
	// 		window.addEventListener('resize', onResize);
	// 	};

	// 	const init = () => {
	// 		addEvents();
	// 	};

	// 	return {
	// 		init
	// 	};

	// })();

	menu.init();
	// search.init();

})($j);
