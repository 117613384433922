function scorePassword(pass) {
	var score = 0;
	if (!pass)
		return score;

	// award every unique letter until 5 repetitions
	var letters = {};
	for (var i = 0; i < pass.length; i++) {
		letters[pass[i]] = (letters[pass[i]] || 0) + 1;
		score += 5.0 / letters[pass[i]];
	}

	// bonus points for mixing it up
	var variations = {
		digits: /\d/.test(pass),
		lower: /[a-z]/.test(pass),
		upper: /[A-Z]/.test(pass),
		nonWords: /\W/.test(pass),
	};

	variationCount = 0;
	for (var check in variations) {
		variationCount += (variations[check] === true) ? 1 : 0;
	}
	score += (variationCount - 1) * 10;

	return parseInt(score);
}

function checkPassStrength(pass) {
	var score = scorePassword(pass);
	var label = '';
	var color = '';
	var width = '';

	if (score > 60) {
		label = "silne";
		width = '100%';
		color = '#1fcb0d';
	} else if (score > 40) {
		label = "średnie";
		width = '66%';
		color = '#ffc200';
	} else if (score >= 15) {
		label = "słabe";
		width = '33%';
		color = '#fb0c12';
	}

	jQuery("#strength_human").text(label);
	jQuery("#strength_score").css('width', width);
	jQuery("#strength_score").css('background-color', color);
}


jQuery(document).ready(function () {
	jQuery("#password").on("keypress keyup keydown", function () {
		var pass = jQuery(this).val();
		checkPassStrength(pass);
	});

	jQuery("#password, #confirmation").on('input', function () {
		this.value = this.value.replace(/[ ]/g, '');
	});
});
