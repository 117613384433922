(($) => {

	"use strict";

	const videos = (() => {

		const $youtube = $(".youtube");

		const loadVideo = (el) => {
			const iframe = document.createElement("iframe");

			iframe.setAttribute("frameborder", "0");
			iframe.setAttribute("allowfullscreen", "");
			iframe.setAttribute("src", "https://www.youtube.com/embed/" + el.dataset.embed + "?rel=0&showinfo=0&autoplay=1");

			el.innerHTML = "";
			el.appendChild(iframe);
		};

		const init = () => {
			$youtube.each((i, el) => {

				const source = "https://img.youtube.com/vi/" + el.dataset.embed + "/sddefault.jpg";
				const image = new Image();

				image.src = source;
				image.addEventListener("load", () => {
					el.appendChild(image);
				});

				el.addEventListener("click", () => {
					loadVideo(el);
				});
			});
		};

		return {
			init
		};

	})();

	videos.init();

})($j);
