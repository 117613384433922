(($) => {
	"use strict";

	const sliderCategory = (() => {

		const $slider = $('.slider-category');
		const arrow = `<svg class="icon icon-arrow-long">
							<use xlink:href="#icon-arrow-long"></use>
						</svg>`;

		const options = {
			items: 2,
			loop: false,
			dots: false,
			nav: true,
			navText: [arrow, arrow],
			responsiveClass: true,
			pullDrag: true,
			mouseDrag: true,
			touchDrag: true,
			responsive: {
				0: {
					items: 2
				},
				420: {
					items: 3
				},
				600: {
					items: 4
				},
				768: {
					items: 5
				},
				960: {
					items: 6,
					mouseDrag: true,
					touchDrag: true,
					pullDrag: true
				},
				1280: {
					items: 8,
					mouseDrag: false,
					touchDrag: false,
					pullDrag: false
				}
			}
		};

		const toggleSlider = () => {
			if (window.innerWidth > 1280) {
				$slider.trigger('destroy.owl.carousel');
			} else {
				$slider.owlCarousel(options);
			}
		};

		const init = () => {
			$(document).ready(function () {
				toggleSlider();
			});
			window.addEventListener('resize', toggleSlider);
		};

		return {
			init
		};

	})();

	const sliderRecommended = (() => {
		const $slider = $('.slider-recommended'),
			$slidernav = $('.slider-recommended-nav'),
			dashArray = 144.51326206513048;

		let $progress = $slidernav.find('.owl-next .bar');

		const arrow = `<svg class="icon icon-arrow-small">
                            <use xlink:href="#icon-arrow-small"></use>
						</svg>
						<svg class="icon-progress" width="50" height="50" viewPort="0 0 50 50" version="1.1" xmlns="http://www.w3.org/2000/svg">
							<circle class="bar" r="23" cx="25" cy="25" stroke-dasharray="${dashArray}"></circle>
						</svg>
						`;

		const getProgress = (item, page) => {
			const lastindex = item.index + page.size;
			const progress = lastindex / item.count * 100;
			return progress || 0;
		};

		const showProgress = (progress) => {
			const r = $progress.attr('r');
			const c = Math.PI * (r * 2);
			progress = progress || 0;
			let pct = ((100 - progress) / 100) * c || 0;
			$progress.css({
				strokeDashoffset: pct
			});
		};

		const truncateText = () => {
			$(".product-name a").dotdotdot({
				truncate: "word"
			});
		};

		const options = {
			items: 1,
			// loop: true, // TODO : arrow loader
			dots: false,
			nav: true,
			navText: [arrow, arrow],
			navContainer: $slidernav,
			margin: 30,
			autoplay: true,
			autoplayTimeout: 4000,
			smartSpeed: 1000,
			autoplayHoverPause: true,
			responsive: {
				0: {
					items: 1
				},
				768: {
					items: 2
				},
				900: {
					items: 1
				},
				1025: {
					items: 2
				},
				1200: {
					items: 3
				},
				1600: {
					items: 4
				}
			},
			onInitialized: e => {
				$progress = $slidernav.find('.owl-next .bar');
				showProgress(getProgress(e.item, e.page));
				truncateText();
			},
			onTranslate: e => {
				showProgress(getProgress(e.item, e.page));
			},
			onResized: e => {
				showProgress(getProgress(e.item, e.page));
			}
		};

		const init = () => {
			$(document).ready(function () {
				$slider.owlCarousel(options);
			});
		};

		return {
			init
		};

	})();

	const sliderMain = (() => {
		const mobileMax = 1024;
		const bgClass = 'slider-bg';
		const $slider = $('.slider-main');
		const arrow = `<svg class="icon icon-arrow-small">
							<use xlink:href="#icon-arrow-small"></use>
						</svg>`;
		const $slidernav = $('.slider-main__nav');
		const getImgUrl = $slide => {
			const $img = $slide.find('.slider-main__image img');
			return $img && $img.attr('src') || $img.data('src');
		};
		const addBg = ($slide) => {
			const imgUrl = getImgUrl($slide);
			if (!imgUrl) {
				return;
			}
			const $bg = $('<span>').addClass(bgClass);
			$bg.css('background-image', `url(${imgUrl})`);
			$slide.prepend($bg);
		};
		const hasBg = $slide => $slide && $slide.find(`.${bgClass}`).length;
		const setBg = $slide => {
			if (!$slide || hasBg($slide)) {
				return;
			}
			addBg($slide);
		};

		const getSlides = () => $slider.find('.slider-main__content');
		let $slides = getSlides();

		const options = {
			items: 1,
			slideby: 1,
			loop: true,
			dots: false,
			nav: true,
			navText: [arrow, arrow],
			navContainer: $slidernav,
			autoplay: true,
			autoplayTimeout: 5000,
			autoplaySpeed: 600,
			smartSpeed: 600,
			autoplayHoverPause: true,
			onInitialized: () => $slides = getSlides(),
			onChanged: e => {
				if (window.innerWidth <= mobileMax) {
					return;
				}
				setBg($slides.eq(e.item.index));
			}
		};

		const init = () => {
			$(document).ready(function () {
				$slider.owlCarousel(options);
			});
		};

		return {
			init
		};

	})();

	const sliderProduct = (() => {

		const $slider = $('.product-image-gallery'),
			$thumbs = $('.product-image-thumbs');

		const goToSlide = index => {
			$slider.owlCarousel().trigger('to.owl.carousel', [index]);
		};

		const markActive = index => {
			const $currentItem = $thumbs.find('li').filter((i, item) => {
				return item.querySelector(`[data-image-index="${index}"]`);
			});
			$currentItem.addClass('active').siblings().removeClass('active');
		};

		const sliderNav = () => {
			$thumbs.on('click', 'a', (e => {
				const dotIndex = e.target.dataset.imageIndex || 0;
				e.preventDefault();
				goToSlide(dotIndex);
				markActive(dotIndex);
			}));
		};

		const options = {
			items: 1,
			slideby: 1,
			animateIn: 'fadeIn',
			animateOut: 'fadeOut',
			onInitialized: sliderNav,
			onTranslated: e => {
				markActive(e.item.index);
			},
			touchDrag: false,
			mouseDrag: false
		};

		const init = () => {
			$(document).ready(function () {
				$slider.owlCarousel(options);
			});
		};

		return {
			init
		};

	})();

	// const sliderUser = (() => {
	// 	const $slider = $('.slider-user');

	// 	const options = {
	// 		items: 1,
	// 		slideby: 1,
	// 		loop: true,
	// 		dots: true,
	// 		animateIn: 'fadeIn',
	// 		animateOut: 'fadeOut',
	// 		autoplay: true,
	// 		autoplayTimeout: 5000,
	// 		autoplaySpeed: 600,
	// 		smartSpeed: 600,
	// 		autoplayHoverPause: true
	// 	};

	// 	const init = () => {
	// 		$(document).ready(function () {
	// 			$slider.owlCarousel(options);
	// 		});
	// 	};

	// 	return {
	// 		init
	// 	};

	// })();

	// sliderUser.init();
	sliderMain.init();
	sliderCategory.init();
	sliderRecommended.init();
	sliderProduct.init();

})($j);
