(($, VarienForm) => {

	"use strict";

	const checkout = (() => {
		const isCheckout = $('.onestepcheckout-index-index').length;
		const currentStepClass = 'checkout__steps-item--current';
		const nextStepClass = 'checkout__steps-item--next';
		const prevStepClass = 'checkout__steps-item--prev';
		const stepsHiddenClass = 'checkout__steps--hidden';
		const $stepsProgress = $('.checkout__steps');
		const $steps = $('[data-step]');
		const addressTypes = ['billing', 'shipping'];

		const toggleItem = e => {
			const $target = $(e.target);
			$target.closest('.checkout__accordion-item').toggleClass('checkout__accordion--expanded');
		};

		const accordion = () => {
			$steps.on('click', '.checkout__accordion-title', toggleItem);
		};

		const toggleCompanyContent = () => {
			let $isNotInvoice = $('#id_is_not_invoice');

			if ($isNotInvoice.is(':checked')) {
				$('.input-company, .input-taxvat').parent('li').hide(0);
				$('.input-lastname .label-annotation, .input-firstname .label-annotation').hide(0);
			} else {
				$('.input-company, .input-taxvat').parent('li').show(0);
				$('.input-lastname .label-annotation, .input-firstname .label-annotation').show(0);
			}
		};

		const toggleCompany = () => {
			if (!$('#id_is_not_invoice').prop('checked')) {
				toggleCompanyContent();
			}
			$steps.on('change', '#id_is_not_invoice', () => {
				toggleCompanyContent();
			});
		};

		const getLggedInuserAddress = (addressType) => {
			return $(`#${addressType}-address-select`).next('.address-select').find('.current').text();
		};

		const getAddress = ($formValueArray, addressType) => {
			const firstname = $formValueArray.find((item) => item.name === `${addressType}[firstname]`).value;
			const lastname = $formValueArray.find((item) => item.name === `${addressType}[lastname]`).value;
			const company = $formValueArray.find((item) => item.name === `${addressType}[company]`).value;
			const street = $formValueArray.find((item) => item.name === `${addressType}[street][0]`).value;
			const postcode = $formValueArray.find((item) => item.name === `${addressType}[postcode]`).value;
			const city = $formValueArray.find((item) => item.name === `${addressType}[city]`).value;
			const name = `${firstname} ${lastname}`;
			const address = `${street}<br>${postcode} ${city}`;
			const content = company ? `${name}<br>${company}<br>${address}` : `${name}<br>${address}`;

			return firstname && lastname ? content : '';
		};

		const setAddress = ($formValueArray, addressType) => {
			let content = getAddress($formValueArray, addressType) || getLggedInuserAddress(addressType);

			if (!content && addressType === addressTypes[1]) {
				content = getAddress($formValueArray, addressTypes[0]);
			}
			$(`.checkout__summary .checkout__address--${addressType}`).html(content);
		};

		const setShippingMethod = ($form, $formValueArray) => {
			const shipmentValue = $formValueArray.find((item) => item.name === 'shipping_method').value;
			const label = $form.find(`label[for="s_method_${shipmentValue}"]`).html();
			$('.checkout__summary .checkout__summary--shipment').html(label);
		};

		const setCouponCode = ($formValueArray) => {
			const content = $formValueArray.find((item) => item.name === 'onestepcheckout-couponcode');
			const $couponCode = $('.checkout__summary .coupon-code');
			content && content.value ? $couponCode.html(content.value) : $couponCode.closest('.block-content').hide();
		};

		const setProductList = () => {
			const $summaryPlaceholder = $('.onestepcheckout-summary-placeholder');
			const $summary = $('.onestepcheckout-summary .onestepcheckout-summary-col').clone();
			$summaryPlaceholder.html($summary);
		};

		const setSummaryPrice = () => {
			const totalPriceText = $('.onestepcheckout-totals td').last().text();
			const shippingPriceText = $('.onestepcheckout-shipping-method-block .price').last().text();
			$('#summary-price-total').html(totalPriceText);
			$('#shipping-price-total').html(shippingPriceText);
		};

		const setSummaryData = () => {
			const $form = $('#onestepcheckout-form');
			const $formValueArray = $form.serializeArray();
			setAddress($formValueArray, addressTypes[0]);
			setAddress($formValueArray, addressTypes[1]);
			setShippingMethod($form, $formValueArray);
			setCouponCode($formValueArray);
			setProductList();
			setSummaryPrice();
		};

		const toggleActiveButton = () => {
			const $shippingMethodsBlock = $('.onestepcheckout-shipping-method-block');
			const hasShippingMethods = $shippingMethodsBlock.find('.shipment-methods').length > 0;
			const $button = $('.onestepcheckout-shipping-method .navigate-button');
			$button.toggleClass('disabled', !hasShippingMethods);
		};

		const toggleActiveOption = (step) => {
			const index = step - 1;
			if (index < 0) {
				$stepsProgress.addClass(stepsHiddenClass);
				return;
			}
			const $activeStep = $stepsProgress.children('li').eq(index);
			$activeStep.attr('class', currentStepClass);
			$activeStep.prevAll().attr('class', prevStepClass);
			$activeStep.nextAll().attr('class', nextStepClass);
			$stepsProgress.removeClass(stepsHiddenClass);
		};

		const toggleStep = (step) => {
			const $activeStep = $(`[data-step="${step}"]`);
			if (!$activeStep || !$activeStep.length) {
				return;
			}

			if (parseInt(step, 10) === 3) {
				toggleActiveButton();
			}

			if (parseInt(step, 10) === 4) {
				setSummaryData();
			}

			$steps.hide();
			$activeStep.show();
			toggleActiveOption(step);
			$('html, body').stop().animate({
				scrollTop: 0
			}, 500);
		};

		const setInitialStep = () => {
			const hash = window.location.hash;
			if (!hash) {
				toggleStep(0);
				return;
			}
			const activeStep = hash.replace('#', '');
			toggleStep(activeStep);
		};

		const subscribePopState = () => {
			window.addEventListener('popstate', (e) => {
				const state = e.state;
				if (!state) {
					setInitialStep();
					return;
				}
				toggleStep(state.step);
			});
		};

		const navigateCart = () => {
			$steps.on('click', '.navigate-button', (e) => {
				e.preventDefault();
				const $button = $(e.target);

				if ($button.is('.onestepcheckout-validate')) {
					const form = new VarienForm('onestepcheckout-form');
					if (!form.validator.validate()) {
						return;
					}
				}
				const step = $button.data('next');
				if (!step) {
					return;
				}
				const state = { step };
				const url = '#' + step;
				window.history.pushState(state, null, url);
				toggleStep(step);
				return true;
			});
		};

		const init = () => {
			if (!isCheckout) {
				return;
			}
			accordion();
			navigateCart();
			toggleCompany();
			subscribePopState();
			setInitialStep();
		};

		return {
			init
		};

	})();

	checkout.init();

})($j, VarienForm);
