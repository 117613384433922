(($) => {
	$.extend(true, $.magnificPopup.defaults, {
		tClose: 'Zamknij (Esc)', // Alt text on close button
		tLoading: 'Pobieranie...', // Text that is displayed during loading. Can contain %curr% and %total% keys
		gallery: {
			tPrev: 'Poprzednie', // Alt text on left arrow
			tNext: 'Nastepne', // Alt text on right arrow
			tCounter: '%curr% z %total%' // Markup for "1 of 7" counter
		},
		image: {
			tError: '<a href="%url%">The image</a> could not be loaded.' // Error message when image could not be loaded
		},
		ajax: {
			tError: '<a href="%url%">The content</a> could not be loaded.' // Error message when ajax request failed
		},
		removalDelay: 500,
		mainClass: 'mfp-zoom-in'
	});


	const popups = (() => {

		const $popupTrigger = $('.popup-open');
		const $lightboxTrigger = $('a[rel="lightbox"]');
		const $productImages = $('.gallery-image');
		const $thumbs = $('.product-image-thumbs li');
		const $productGallery = $('.product-image-gallery');

		const lightboxOptions = {
			type: 'image'
		};

		const setActiveItem = i => {
			if ($thumbs.length && $productGallery.length) {
				$thumbs.removeClass('active');
				$thumbs.eq(i).addClass('active');
				$productGallery.owlCarousel().trigger('to.owl.carousel', [i, 0]);
			}
		};

		const productImageOptions = {
			type: 'image',
			gallery: {
				enabled: true
			},
			callbacks: {
				beforeClose: function () {
					setActiveItem(this.index);
				}
			}
		};

		productImageOptions.items = $productImages.toArray().map((image) => {
			return {
				src: image.dataset['zoomImage'] ? image.dataset['zoomImage'] : image.src
			};
		});

		const popupOptions = {
			focus: $(this).find('input').eq(0),
			callbacks: {
				close: function () {
					$('html').removeClass('remodal-is-locked');
					$('body').removeClass('modal-open');
				}
			}
		};

		const setCurrent = e => {
			const currentIndex = $productImages.index(e.target) || 0;
			$productImages.magnificPopup('goTo', currentIndex);
		};

		const init = () => {
			$lightboxTrigger.magnificPopup(lightboxOptions);
			$popupTrigger.magnificPopup(popupOptions);
			$productImages.magnificPopup(productImageOptions);
			$productImages.on('click', setCurrent);
		};

		return {
			init
		};

	})();

	const advancedSearch = (() => {
		const search_autocompletes = document.querySelectorAll('.searchautocomplete');
		const homeSearch = document.querySelector('.section-search');
		const isHome = !!homeSearch;

		const observeElem = (elem, callback) => {
			const observerObject = new MutationObserver(callback);

			observerObject.observe(elem, {
				attributes: true,
				attributeOldValue: true,
				childList: true
			});
		};

		const elAdded = mutationRecord => {
			if (mutationRecord.addedNodes) {
				return Array.from(mutationRecord.addedNodes).filter(item => {
					return item.dataset ? item.dataset['mfpSrc'] : false;
				});
			}

			return false;
		};

		const addPopupTrigger = () => {
			const $popupLink = $('.advanced-search-open');
			if ($popupLink.length && $.magnificPopup) {
				$popupLink.magnificPopup();
			}
		};

		const addModalTrigger = mutationRecordsList => {
			mutationRecordsList.forEach(mutationRecord => {
				const newEl = elAdded(mutationRecord);

				if (newEl) {
					addPopupTrigger();
					return;
				}
			});
		};

		const updateOffset = mutationRecordsList => {
			mutationRecordsList.forEach(mutationRecord => {
				homeSearch.classList.toggle('autocomplete-visible', mutationRecord.target.classList.contains('active'));
			});
		};

		const elUpdate = mutationRecordsList => {
			addModalTrigger(mutationRecordsList);
			if (isHome) {
				updateOffset(mutationRecordsList);
			}
		};

		const init = () => {
			if (search_autocompletes.length) {
				[].slice.call(search_autocompletes).map(el => {
					observeElem(el, elUpdate);
				});
			}
		};

		return {
			init
		};

	})();

	const promotion = ( () => {

		const $promotionPopup = $('#promotion-popup');
		const $body = $('body');
		const openClass = 'modal-promotion-open';
		const storage = $promotionPopup.data('storage') || 'popup_promotion';

		const showIt = () => !localStorage.getItem(storage);
		const saveSeen = () => localStorage.setItem(storage, 1);

		const init = () => {
			if ($promotionPopup.length && showIt()) {
				$.magnificPopup.open({
					items: {
						src: $promotionPopup,
						type: 'inline'
					},
					callbacks: {
						open: () => {
							$body.addClass(openClass);
							saveSeen();
						},
						afterClose: () => {
							$body.removeClass(openClass);
						},
					  }
				});
			}
		};

		return {
			init
		};

	})();

	promotion.init();
	advancedSearch.init();
	popups.init();


})($j);
