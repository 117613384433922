(($) => {

	"use strict";

	const calendar = (() => {
		const $productList = $('.product-list-calendar .products-grid');
		const activeClass = 'active';
		const visibleClass = 'visible';
		const specialClass = 'special';
		const readyClass = 'items_ready';
		const animationClass = 'animation-on';
		const year = 2019;
		const month = 11;
		const text = {
			title: 'Dzień Darmowej Dostawy',
			description: 'Wszystkie zakupy dokonane przez klientów 10.12.2019 będą realizowane bez kosztów dostawy. Promocja dotyczy całego asortymentu dostępnego na sklep.tiptopol.pl'
		};

		// const products = ['INVB600P', 'INVHD710500VT', 'NO130101', 'CP7762', 'INVADVANCE-B',  'GDHD100500', 'GDHD75270',  '562-3941Z', 'AVLVS9125', specialClass, 'INVB600', 'INVSL230NI', 'BUTOREK32WK']; // local

		// const products = ['W-DC-K0033220', 'TXPROMO1', 'W-DC-K000825', 'W-DC-L0805', 'W-DC-K01035', 'W-DC-K0041220', 'W-DC-K004125', 'W-DC-K004110', 'W-DC-K00415', 'special',
		// 	'W-DC-L826210', 'W-DC-L82620', 'W-DC-RAINXRR', 'W-DC-K0033110', 'W-DC-K0031220', 'W-DC-K0031110', 'W-DC-K00315', 'W-DC-7628', 'W-DC-7616', 'W-DC-M400',
		// 	'W-DC-464224', 'W-DC-M957850', 'MXML6045']; //  test

		const products = ['PA242-1002', 'INVWT200', 'CP7748NG', '562-2809', 'INVB6001040', 'W-BGS8656', 'PX43', 'W-BGS3965', 'TX1234yf-5,0', 'special', '593-2020', '512-1207', '512-5060', 'CRW003-150', 'TPALU-FE1NTC', '593-0645', 'CRW002', 'CP000-0400', 'W-BGS1988', 'NO130104', 'W-BGS9373', '519-9356', 'CP7035AD', 'W-BGS9736']; // prod

		const getToday = () => {
			const today = new Date();
			return {
				day: today.getDate(),
				month: today.getMonth(),
				year: today.getFullYear()
			};
		};

		const formatlabels = (dayIndex) => {
			if (dayIndex < 0) {
				return null;
			}
			const day = dayIndex + 1;
			const displayMonth = month + 1;
			const dayString = day > 9 ? `${day}` : `0${day}`;
			const date = new Date(year, month, day);
			const weekday = date.toLocaleString('pl-PL', { weekday: 'short' });
			return {
				weekday,
				date: `${dayString}.${displayMonth}`,
			};
		};

		const createSpecialBox = ($items, specialDayIndex, currentDay) => {
			const item = $items && $items[0] && $items[0].cloneNode(true);
			if (!item || specialDayIndex < 0) {
				return;
			}
			item.removeAttribute('data-sku');
			const labels = formatlabels(specialDayIndex);
			if (labels) {
				item.dataset.date = labels.date;
				item.dataset.weekday = labels.weekday;
			}
			const isActive = currentDay === specialDayIndex + 1;
			const isVisible = specialDayIndex < currentDay;
			isActive ? item.classList.add(activeClass) : item.classList.remove(activeClass);
			isVisible ? item.classList.add(visibleClass) : item.classList.remove(visibleClass);

			$(item).append(`<div class="item-content"><p class="item-title">${text.title}</p><p class="item-description">${text.description}<p><div>`);
			item.classList.add(specialClass);
			$items[specialDayIndex].before(item);
		};

		const isPromotionActive = () => {
			const today = getToday();
			return today.year === year && today.month === month;
		};

		const openItem = (e) => {
			const item = e.target;
			if (item && item.classList) {
				item.classList.add(animationClass);
				setTimeout(() => {
					item.classList.remove(animationClass);
					item.classList.add(visibleClass);
				}, 350);
			}
		};

		const addListeners = () => {
			$productList.on('click', openItem);
		};

		const initItems = () => {
			const currentDay = getToday().day;
			const specialDayIndex = products.indexOf(specialClass);
			const $items = $productList.find('.item');

			$items.each((_i, item) => {
				const promotionDayIndex = products.indexOf(item.dataset.sku);
				const promotionDay = promotionDayIndex + 1;
				const isVisible = promotionDay && promotionDay <= currentDay;
				const isActive = promotionDay === currentDay;
				const labels = formatlabels(promotionDayIndex);

				if (labels) {
					item.dataset.date = labels.date;
					item.dataset.weekday = labels.weekday;
				}

				if (isActive) {
					item.classList.add(activeClass);
					item.classList.add(visibleClass);
				}

				if (isVisible && isPromotionActive()) {
					item.classList.add(visibleClass);
				}
			});

			createSpecialBox($items, specialDayIndex, currentDay);
			$productList.addClass(readyClass);
		};

		const init = () => {
			if (!$productList) {
				return;
			}
			initItems();
			addListeners();
		};

		return {
			init
		};

	})();

	calendar.init();

})($j);
