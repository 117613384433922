class Tpms {
	constructor($, sheetId, tpmsId) {
		this.$ = $;
		this.sheetId = sheetId;
		this.tpmsId = tpmsId;
		this.visibleClass = 'visible';
		this.modalHiddenClass = 'mfp-hide';
		this.prefix = { sku: 'sku', info: 'info' };
		this.$products = $('.product-list-tpms').find('.item');
		this.$elements = $(`.${tpmsId}`);
		this.$params = $('.tpms-params');
		this.$modal = $(`#${tpmsId}-popup`);

		this.init();
	}

	init() {
		this.initParams();
		this.initState(this.$elements);
		this.initWorker();
		this.initData();
	}

	initWorker() {
		this.worker = new Worker(`${location.origin}/skin/frontend/tiptopolmodern/default/js/webworker.min.js`);
	}

	initData() {
		try {
			const cachedRecords = this.cachedRecords;
			if (cachedRecords) {
				this.initSearch(cachedRecords);
				return;
			}
		}
		catch(e) {
			this.loadData();
			return;
		}

		this.loadData();
	}

	initParams() {
		this.$additional = this.$params.find('.additional-information');
		this.$frequency = this.$params.find('.frequency');
		this.$procedure = this.$params.find('.procedure');
		this.$oeProducer = this.$params.find('.oe-producer');
		this.$oeId =  this.$params.find('.oe-id');
		this.$rim =  this.$params.find('.rim');

		this.infoData = {
			additional: {
				key: `${this.prefix.info}informacjedodatkowe`,
				$element: this.$additional,
				$value: this.$additional.find('.value')
			},
			frequency: {
				key: `${this.prefix.info}częstotliwość`,
				$element: this.$frequency,
				$value: this.$frequency.find('.value')
			},
			procedure: {
				key: `${this.prefix.info}procedura`,
				$element: this.$procedure,
				$value: this.$procedure.find('.value')
			},
			oeProducer: {
				key: `${this.prefix.info}producentczujnikaoe`,
				$element: this.$oeProducer,
				$value: this.$oeProducer.find('.value')
			},
			oeId: {
				key: `${this.prefix.info}numerseryjnyczujnikaoe`,
				$element: this.$oeId,
				$value: this.$oeId.find('.value')
			},
			rim: {
				key: `${this.prefix.info}obręcz`,
				$element: this.$rim,
				$value: this.$rim.find('.value')
			}
		};
	}

	initState($elements) {
		this.state = {
			selected: {},
			records: [],
			filters: {},
			marka: {
				$element: $elements.filter('.tpms-brand'),
				label: 'Marka',
				list: [],
				filters: {},
				child: 'model'
			},
			model: {
				$element: $elements.filter('.tpms-model'),
				label: 'Model',
				list: [],
				filters: {},
				child: 'rocznik'
			},
			rocznik: {
				$element: $elements.filter('.tpms-year'),
				label: 'Rocznik',
				list: []
			}
		};
	}

	loadData() {
		this.data.then(response => {
			this.getEntries(response);
		},
		error => {
			console.log(error);
			return { message: 'Nie udało się pobrać produktów. Spróbuj później.' };
		});
	}

	isId(value) {
		return value.indexOf(this.prefix.sku) === 0 && !this.state[value];
	}

	isParam(value) {
		return value.indexOf(this.prefix.info) === 0 && !this.state[value];
	}

	get hasDisabledOption() {
		return !!this.$elements.filter(':disabled').length;
	}

	get isFilterSet() {
		return (this.state.selected.model && this.state.selected.marka && this.state.selected.rocznik) ||
			(this.state.selected.model && this.state.selected.marka && this.hasDisabledOption);
	}

	get isModal() {
		return !!this.$modal.length && !this.$modal.hasClass(this.modalHiddenClass);
	}

	setModel(records) {
		if (!records) {
			return;
		}
		const filters = this.getFilters(records);

		this.state.marka.list = filters.marka;
		this.state.marka.filters = this.getBrands(records);

		this.state.model.list = filters.model;
		this.state.model.filters = this.getModels(records);

		this.state.rocznik.list = filters.rocznik;

		this.setFilter('marka');
		this.setFilter('model');
		this.setFilter('rocznik');
	}

	setSearchParams() {
		const search = location.search;
		if (!search) {
			return;
		}

		const paramsArray = search.split('&');
		if (!paramsArray[0].includes(this.tpmsId)) {
			return;
		}

		let params = {};
		paramsArray.forEach(paramStr => {
			let [ prop, val ] = paramStr.split('=');
			if (!prop) {
				return;
			}
			prop = prop.replace('?', '');
			if (prop === 'id') {
				return;
			}
			const valDecoded = decodeURIComponent(val.replace(/\+/g, '%20'));
			params[prop] = valDecoded;
			this.updateFilter(prop, valDecoded);
		});
	}

	initSearch(records) {
		this.state.records = records;
		this.setModel(records);
		this.setSearchParams();
	}

	getEntries(data) {
		const entries = data ? data.feed.entry : [];
		this.worker.postMessage({ type: 'getEntries', prefix: 'gsx$', entries, id: this.tpmsId });
		this.worker.onmessage = (message) => {
			if (message.data.id === this.tpmsId) {
				this.initSearch(message.data.entries);
				this.cacheRecords(this.state.records);
				this.worker.terminate();
			}
		};
	}

	cacheRecords(data) {
		sessionStorage.setItem(this.tpmsId, JSON.stringify(data));
	}

	get cachedRecords() {
		const cached = sessionStorage.getItem(this.tpmsId);
		return cached ? JSON.parse(cached) : false;
	}

	get data() {
		const url = `https://spreadsheets.google.com/feeds/list/${this.sheetId}/od6/public/values?alt=json`;
		const options = {
			method: 'get',
			url: url
		};
		return this.$.ajax(options);
	}

	getFilters(entries) {
		const filters = {
		  marka: [],
		  model: [],
		  rocznik: []
		};

		entries.forEach(entry => {
		  const keys = Object.keys(entry);
		  keys.forEach(key => {
			  if (filters[key] && entry[key]) {
				if (filters[key].indexOf(entry[key]) < 0) {
				  filters[key].push(entry[key]);
				}
			  }
			});
		});

		return filters;
	}

	getBrands(entries) {
		const brands = {};

		entries.forEach(entry => {
			if (!brands[entry.marka]) {
				brands[entry.marka] = [];
			}

			if (brands[entry.marka].indexOf(entry.model) < 0 ) {
				brands[entry.marka].push(entry.model);
			}
		});

		return brands;
	}

	getModels(entries) {
		const models = {};

		entries.forEach(entry => {
			if (!models[entry.model]) {
				models[entry.model] = [];
			}

			if (models[entry.model].indexOf(entry.rocznik) < 0 ) {
				models[entry.model].push(entry.rocznik);
			}
		});

		return models;
	}

	setFilter(filterType) {
		const filter = this.state[filterType];

		if (!filter) {
			return;
		}

		const options = {
			language: 'pl',
			width: null,
			maximumSelectionSize: 1
		};

		const $elem = filter.$element;
		const child = this.state[filter.child];
		const elemOptions = {
			placeholder: filter.label,
			data: this.formData(filter.list)
		};

		$elem.select2(Object.assign(options, elemOptions));

		$elem.on('select2:select', e => {
			const newVal = e.params.data;
			const hasChanged = !this.state.selected[filterType] || this.state.selected[filterType].text !== newVal.text;
			if (!hasChanged) {
				return;
			}
			this.updateState(filterType, newVal);
			setTimeout(() => {
				this.showRecords();
			});
		});

		if (!child) {
			return;
		}

		$elem.on('change', e => {
			let list = filter.filters[e.target.value];
			if (!list && e.target.value) {
				const key = this.findFilterKey(Object.keys(filter.filters), e.target.value);
				list = key ? filter.filters[key] : [];
			}
			const formedData = this.formData(list);
			const disabled = formedData && !formedData.length;
			const childOptions2 = {
				data: formedData,
				placeholder: child.label,
				disabled
			};
			child.$element.select2(Object.assign(options, childOptions2));
		});
	}

	findFilterKey(filterKeys, key) {
		return filterKeys.find((_key) => _key.replace(/,/g, '') === key);
	}

	formData(data) {
		return data ? data.filter(item => item).map(item => this.formDataItem(item)) : data;
	}

	formDataItem(item, selected = false) {
		return item && typeof item === 'string' ? this.formOption(item, selected) : item;
	}

	formOption(item, selected) {
		return { id: item.replace(/,/g, ''), text: item, selected: !!selected };
	}

	updateFilter(filterType, val) {
		const item = this.state[filterType];
		if (!item) {
			return;
		}
		const $elem = item.$element;
		if (!val) {
			$elem.val(null);
		} else {
			const selected = this.formDataItem(val, true);
			$elem.val(selected.id);
			$elem.trigger({
				type: 'select2:select',
				params: { data: selected }
			});
		}

		$elem.trigger('change');
	}

	buildSearchParams(params) {
		const keys = Object.keys(params);
		const collection = keys.filter(key => params[key].selected).map(key => `${key}=${params[key].text}`);

		return `id=${this.tpmsId}&${collection.join('&')}`;
	}

	searchUrl(url) {
		return url.split('?')[0] + '?' + this.buildSearchParams(this.state.selected);
	}

	updateHistory() {
		const newUrl = this.searchUrl(window.location.href);
		window.history.replaceState({ path: newUrl }, '', newUrl);
	}

	updateState(filterType, val) {
		let parent = this.state[filterType];
		this.state.selected[filterType] = val || '';

		while (parent.child) {
			this.state.selected[parent.child] = '';
			this.updateFilter(parent.child, '');
			parent = this.state[parent.child];
		}

		if (!this.isModal) {
			this.updateHistory();
		}

		return this.state.selected;
	}

	getInfoData(records) {
		const params = {};
		records.forEach(record => {
			Object.keys(record)
				.filter(key => !this.isId(key))
				.forEach(key => {
					if (this.isParam(key)) {
						params[key] = record[key];
					}
				});
		});

		return params;
	}

	showParam(key, value) {
		if (this.infoData[key] && value) {
			this.infoData[key].$value.html(value);
			this.infoData[key].$element.addClass(this.visibleClass);
			return;
		}
		this.infoData[key].$element.removeClass(this.visibleClass);
	}

	showParams(records) {
		if (this.isFilterSet) {
			this.$params.addClass(this.visibleClass);
			this.setParamValues(records);
			return;
		}
		this.$params.removeClass(this.visibleClass);
	}

	setParamValues(records) {
		const paramValues = this.getInfoData(records);
		Object.keys(this.infoData).forEach(key => {
			this.showParam(key, paramValues[this.infoData[key].key]);
		});
	}

	getIds(records) {
		return records.map(record => {
			return Object.keys(record)
				.filter(key => record[key] && !this.state.hasOwnProperty(key) && this.isId(key))
				.map(key => record[key]);
			}).reduce((prev, curr) => prev.concat(curr), []);
	}

	showRecords() {
		if (!this.state.records.length || this.isModal) {
			return;
		}
		const recordsFiltered = this.state.records.filter(record => {
			const filters = Object.keys(this.state.selected);
			let results = 0;
			if (this.hasDisabledOption) {
				filters.pop();
			}
			if (this.state.selected.model && record.model === this.state.selected.model.text) {
				results++;
			}
			if (this.state.selected.marka && record.marka === this.state.selected.marka.text) {
				results++;
			}
			if (this.state.selected.rocznik && record.rocznik === this.state.selected.rocznik.text) {
				results++;
			}
			return filters.length && results === filters.length;
		});

		const ids = this.getIds(recordsFiltered);
		const $productsFiltered = this.$products.filter((_i, item) => ids.indexOf(item.dataset.sku) > -1);
		this.showParams(recordsFiltered);
		this.$products.removeClass(this.visibleClass);
		$productsFiltered.addClass(this.visibleClass);
	}
}

(($) => {

	"use strict";

	const tpms = (() => {
		const tpms_1_id = 'tpms_1';
		const tpms_2_id = 'tpms_2';
		const tpms_1_sheet_id = '1v0rHnSRJCiN_v4sSNiEEHf5VYYr7YQJbQrtifX2jlz8';
		const tpms_2_sheet_id = '1Imo22BrBDdZfP3vaWnO77_VRrtCJAMvZUcEPDyn8h7U';

		const removeElements = (tpmsId) => {
			if ($('.page-description').hasClass(tpmsId)) {
				$(`#header-${tpmsId}`).remove();
				$(`#${tpmsId}-popup`).remove();
			}
		};

		const initTpms = (sheetId, tpmsId) => {
			removeElements(tpmsId);
			new Tpms($, sheetId, tpmsId);
		};

		const init = () => {
			initTpms(tpms_1_sheet_id, tpms_1_id);
			initTpms(tpms_2_sheet_id, tpms_2_id);
		};

		return {
			init
		};

	})();

	tpms.init();

})($j);
