if (typeof Validation === "function") {
	Validation.add('validate-zip-poland', 'Kod pocztowy musi być w formacie 00-000 (np.61-123)', function (v) {
		return Validation.get('IsEmpty').test(v) || /(^\d{2}-\d{3}$)/.test(v);
	});
}

$j(document).ready(function () {
	$j("#street_1, input[id='billing:street1'], input[id='shipping:street1']").attr("placeholder", "Ulica");
	$j("#street_2, input[id='billing:street2'], input[id='shipping:street2']").attr("placeholder", "Nr domu");
	$j("#street_3, input[id='billing:street3'], input[id='shipping:street3']").attr("placeholder", "Nr lokalu");
	$j('[id*="taxvat"]').mask("999-999-99-99");
	$j('[id*="telephone"]').on('input', function () {
		this.value = this.value.replace(/[^0-9]/g, '');
	});
	$j('.validate-zip-international').mask('99-999');
	$j('#checkout-billing-zip-code').mask('99-999');
	$j('#checkout-shipping-zip-code').mask('99-999');
});

// $j(document).on('change', "#country, select[id='shipping:country_id'], select[id='billing:country_id']", function () {

// 	var selectedCountry = $j(this).find(":selected").text();
// 	var postcodeClass = $j(this).parent().data('zip');
// 	var postcode = $j('.' + postcodeClass);
// 	if (selectedCountry === "Polska") {
// 		postcode.removeClass('validate-zip-international').addClass('validate-zip-poland');
// 	} else {
// 		postcode.removeClass('validate-zip-poland').addClass('validate-zip-international');
// 	}
// });
