Parsley.addMessages('pl', {
	defaultMessage: "Wartość wygląda na nieprawidłową",
	type: {
		email: "Wpisz poprawny adres e-mail.",
		url: "Wpisz poprawny adres URL.",
		number: "Wpisz poprawną liczbę.",
		integer: "Dozwolone są jedynie liczby całkowite.",
		digits: "Dozwolone są jedynie cyfry.",
		alphanum: "Dozwolone są jedynie znaki alfanumeryczne."
	},
	notblank: "Pole nie może być puste.",
	required: "Pole jest wymagane.",
	pattern: "Pole zawiera nieprawidłową wartość.",
	min: "Wartość nie może być mniejsza od %s.",
	max: "Wartość nie może być większa od %s.",
	range: "Wartość powinna zaweriać się pomiędzy %s a %s.",
	minlength: "Minimalna ilość znaków wynosi %s.",
	maxlength: "Maksymalna ilość znaków wynosi %s.",
	length: "Ilość znaków wynosi od %s do %s.",
	mincheck: "Wybierz minimalnie %s opcji.",
	maxcheck: "Wybierz maksymalnie %s opcji.",
	check: "Wybierz od %s do %s opcji.",
	equalto: "Wartości nie są identyczne."
});

Parsley.setLocale('pl');

(($) => {

	"use strict";

	const forms = (() => {

		const parsleyConfig = {
			errorsWrapper: '<ul class="parsley-errors-list list-unstyled"></ul>',
			errorsContainer: el => {
				const $err = el.$element.parent();
				return $err;
			},
			classHandler: el => {
				return el.$element.parent();
			}
		};

		const textAreaGrow = () => {
			const autosizeSelector = '.input-box textarea, .autosize';
			autosize(document.querySelectorAll(autosizeSelector));
		};

		const formValidate = () => {
			const $formValidate = $('.form-validate');
			if ($formValidate.length) {
				$formValidate.each((i, item) => {
					$(item).parsley(parsleyConfig);
				});

			}
		};

		const formConsultation = () => {
			const $formConsultation = $('.consultation-form');
			if ($formConsultation.length) {
				$formConsultation.parsley(parsleyConfig);
			}
		};

		const init = () => {
			textAreaGrow();
			formValidate();
			formConsultation();
		};

		return {
			init
		};

	})();

	const newsletter = (() => {

		const $newsletterForm = $('#newsletter-validate-detail');
		const $newsletterPopup = $('#newsletter-popup');
		const $newsletterFormPopup = $newsletterPopup.find('form');
		const $subscribeMessage = $newsletterPopup.find('.subscribe-message');

		if (!$newsletterForm.length) {
			return;
		}

		const $modalBtn = $newsletterForm.find('button[type=submit]');
		const $modal = $($modalBtn.data('mfp-src'));
		const $submitBtn = $modal.find('button');
		const $agreement = $('#newsletter-agreement');
		const $agreementCheckbox = $modal.find('input[type=checkbox]');
		const $checkboxes = $modal.find('input[type=checkbox]');

		const copyAgreement = () => {
			$agreement.val($agreementCheckbox.prop('checked'));
		};

		const copyCheckbox = () => {
			const $checkboxes = $modal.find('input[type=checkbox]');
			$checkboxes.map((i, checkbox) => {
				const copySelector = checkbox.id.replace('-checkbox', '');
				const $copyTo = $(`#${copySelector}`);
				$copyTo.val(checkbox.checked);
			});
		};

		const agreementValid = () => {
			$.magnificPopup.open({
				items: {
					src: $modalBtn.data('mfp-src'),
				},
				callbacks: {
					open: function() {
						$subscribeMessage.removeClass('error').removeClass('success');
						$newsletterPopup.find('.message').html('');
						$agreementCheckbox.prop('checked', false);
					}
				},
			});

			return false;
		};

		const toggleMessageType = (isValid, message) => {
			$subscribeMessage.toggleClass('error', !isValid);
			$subscribeMessage.toggleClass('success', isValid);
			$subscribeMessage.find('.message').html(message);
		};

		const subscribe = email => {
			$.ajax({
				url: '/advoxnewsletter/subscribe/ajax',
				type: 'post',
				data: {
					email,
                    newsletterRegulations: $('#newsletter-regulations-checkbox:checked').val(),
                    newsletterAgreement: $('#newsletter-mail-checkbox:checked').val()
				},
				success: response => {
					response = JSON.parse(response);
					toggleMessageType(response.status === 'success', response.message);
					$newsletterForm.find('input[type="email"]').val('');
				}
			});
		};

		const submitForm = () => {
			const $modalForm = $modal.find('form');
			if ($modalForm.length) {
				$modalForm.parsley()
					.whenValidate()
					.done(() => {
						const email = $('.newsletter-validate-detail #newsletter').val();

						gReCaptchaNewsletterSubmit(() => {
							console.log('x');
							subscribe(email);
						});
					});
			}
		};

		const addEvents = () => {
			$agreementCheckbox.on('change', copyAgreement);
			$checkboxes.on('change', copyCheckbox);
			$newsletterForm.parsley().on('form:submit', agreementValid);
			$submitBtn.on('click', submitForm);
			$newsletterFormPopup.on('submit', e => e.preventDefault());
		};

		const init = () => {
			addEvents();
		};

		return {
			init
		};

	})();

	const dropdown = (() => {

		const $select = $('select').not('.select-filter, [id*="region"], [id*="country"]');
		const addEvents = () => $select.niceSelect();
		const init = () => addEvents();

		return {
			init
		};

	})();

	const selectFilter = (() => {

		const $selectRegion = $('[id*="region"]').not('select');

		const regions = [
			"Dolnośląskie",
			"Kujawsko-Pomorskie",
			"Lubelskie",
			"Lubuskie",
			"Łódzkie",
			"Małopolskie",
			"Mazowieckie",
			"Opolskie",
			"Podkarpackie",
			"Podlaskie",
			"Pomorskie",
			"Śląskie",
			"Świętokrzyskie",
			"Warmińsko-Mazurskie",
			"Wielkopolskie",
			"Zachodniopomorskie"
		];

		const addEvents = () => {

			if ($selectRegion.length) {
				$selectRegion.select2({
					language: 'pl',
					width: null,
					placeholder: "Wybierz województwo",
					maximumSelectionSize: 1,
					data: regions
				});
			}

			// pole województwo usunięte z modala
			// $selectRegion.on('select2:select', () => {
			// 	const $form = $('.consultation-form');
			// 	if ($form.length) {
			// 		const isDirty = $form.attr('_lpchecked');
			// 		if (isDirty) {
			// 			$('.consultation-form').parsley().validate();
			// 		}
			// 	}
			// });

		};

		const init = () => addEvents();

		return {
			init
		};

	})();

	const location = (() => {
		const url = 'https://maps.googleapis.com/maps/api/geocode/json';
		const API_KEY = 'AIzaSyDRQjPpRnqpnNsxhESu_iacNdGtIUGPSVs';
		const locationBtn = document.querySelector('.button-location');
		const locationInput = document.getElementById('location');
		const locationErrorType = 'locationError';
		const messages = {
			location: {
				error: 'Nie udało się pobrać lokalizacji.',
				progress: 'Pobieranie lokalizacji...'
			}
		};

		const saveAddress = address => {
			localStorage.setItem('address', address);
		};

		const getSavedAddress = () => {
			return localStorage.getItem('address');
		};

		const setLocationInput = val => {
			locationInput.value = val;
		};

		const removeError = () => {
			$(locationInput).parsley().removeError(locationErrorType);
		};

		const getFromAddresses = addressList => {
			return addressList[0].hasOwnProperty('address_components') ?
				addressList[0]['address_components'][5]['long_name'] : false;
		};

		const inputReset = input => {
			$(input).parsley().reset();
		};

		const addInputMessage = (input, message, messageType) => {
			const $input = $(input).parsley();
			inputReset(input);
			$input.addError(messageType, {
				message: message,
				updateClass: true
			});
		};

		const locationError = () => {
			addInputMessage(locationInput, messages.location.error, locationErrorType);
		};

		const locationSuccess = (results) => {

			if (Array.isArray(results)) {
				const address = getFromAddresses(results);

				if (!getSavedAddress() || getSavedAddress() !== address) {
					saveAddress(address);
				}
				setLocationInput(address);
				inputReset(locationInput);
			} else {
				addInputMessage(locationInput, messages.location.error, locationErrorType);
			}

		};

		const getAddress = (position, success, error) => {
			if (!position.lat || !position.lng) {
				console.log('returning error');
				return error();
			}

			$.getJSON({
				method: 'get',
				url: url,
				data: {
					sensor: false,
					key: API_KEY,
					latlng: `${position.lat},${position.lng}`
				},
				success: (data) => {
					success(data.results);
				},
				error: () => {
					error();
				}
			});
		};

		const getLocation = (success, error) => {

			if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition(position => {
					const pos = {
						lat: position.coords.latitude,
						lng: position.coords.longitude
					};
					getAddress(pos, success, error);

				}, () => {
					success();
				});
			} else {
				error();
			}
		};

		const locationBtnClick = () => {
			const address = localStorage.getItem('address');

			if (address && address.length > 3) {
				if (locationInput.value.trim().length > 0) {
					addInputMessage(locationInput, messages.location.progress, locationError);
					getLocation(locationSuccess, locationError);
				} else {
					setLocationInput(address);
					inputReset(locationInput);
				}
			} else {
				addInputMessage(locationInput, messages.location.progress, locationError);
				getLocation(locationSuccess, locationError);
			}
		};

		const addEvents = () => {
			if (locationInput && locationBtn) {
				locationBtn.addEventListener('click', locationBtnClick);
				locationInput.addEventListener('keyup', removeError);
			}
		};

		const init = () => {
			addEvents();
		};

		return {
			init
		};
	})();

	const range = (() => {

		let range;
		const inputs = {};
		const options = {};

		const getInputs = (inputs, range) => {
			if (range) {
				inputs.min = range.querySelector('input[name="price[from]"]') || false;
				inputs.max = range.querySelector('input[name="price[to]"]') || false;
			}
		};

		const updateNumeric = options => {
			const numeric = ['min', 'max', 'step', 'begin', 'end'];
			for (let key in options) {
				if (numeric.indexOf(key) > -1) {
					options[key] = parseInt(options[key]) || 0;
				}
			}
		};

		const getOptions = (options, range) => {

			Object.assign(options, range.dataset);
			updateNumeric(options);

			options.min = options.min || 0;
			options.max = options.max ?
				options.max > options.min ?
					options.max : options.min + 1000 :
				options.min + 1000;

			options.range = {
				'min': options.min,
				'max': options.max
			};

			options.start = [
				options.begin <= options.min ? options.begin : (options.max + options.min) * 0.25,
				options.end <= options.max ? options.end : (options.max + options.min) * 0.75
			];

			options.step = options.step < options.max ? options.step : 0;

			options.tooltips = options.tooltips || true;
			options.connect = true;

			options.currency = ` ${options.currency}` || ' zł';

			options.format = {
				to: value => {
					value = parseInt(value);
					return value.toLocaleString('pl-PL', {
						minimumFractionDigits: 0,
						maximumFractionDigits: 0
					}) + options.currency;
				},
				from: value => {
					return value.replace(options.currency, '');
				}
			};

		};

		const updateInputs = (values, handle) => {
			let value = values[handle].length ? values[handle].replace(/ |\u00a0/g, '') : 0;
			value = parseInt(value);

			if (handle === 0 && inputs.min) {
				inputs.min.value = value;
			} else if (handle === 1 && inputs.max) {
				inputs.max.value = value;
			}
		};

		const init = (elementId) => {
			range = document.getElementById(elementId);
			if (range && noUiSlider) {
				getInputs(inputs, range);
				getOptions(options, range);
				noUiSlider.create(range, options);
				range.noUiSlider.on('update', updateInputs);
			}
		};

		return {
			init
		};

	})();

	forms.init();
	newsletter.init();
	dropdown.init();
	selectFilter.init();
	location.init();
	range.init('range');
	range.init('advanced-search-main-range');

})($j);
