(() => {

	"use strict";

	const accountCreate = (() => {
		const form = document.querySelector('.registration-form');
		const invalidSelector = '.validation-failed';

		const callback = () => {
			const cart = window.Cart;
			const isInvalid = form.querySelectorAll(invalidSelector).length > 0;
			if (!cart || isInvalid) {
				return;
			}
			cart.toggleLoader(true);
		};

		const addListeners = () => {

			if (!form) {
				return;
			}
			form.addEventListener('submit', callback);
			window.addEventListener('beforeunload', () => form.removeEventListener('submit', callback));
		};

		const init = () => {
			addListeners();
		};

		return {
			init
		};

	})();

	accountCreate.init();

})();
