(($, window) => {

	"use strict";

	const maps = (() => {

		const mapStyles = [
			{
				"elementType": "geometry",
				"stylers": [{
					"color": "#f5f5f5"
				}]
			},
			{
				"elementType": "labels.icon",
				"stylers": [{
					"visibility": "off"
				}]
			},
			{
				"elementType": "labels.text.fill",
				"stylers": [{
					"color": "#616161"
				}]
			},
			{
				"elementType": "labels.text.stroke",
				"stylers": [{
					"color": "#f5f5f5"
				}]
			},
			{
				"featureType": "administrative.land_parcel",
				"elementType": "labels.text.fill",
				"stylers": [{
					"color": "#bdbdbd"
				}]
			},
			{
				"featureType": "poi",
				"elementType": "geometry",
				"stylers": [{
					"color": "#eeeeee"
				}]
			},
			{
				"featureType": "poi",
				"elementType": "labels.text.fill",
				"stylers": [{
					"color": "#757575"
				}]
			},
			{
				"featureType": "poi.park",
				"elementType": "geometry",
				"stylers": [{
					"color": "#e5e5e5"
				}]
			},
			{
				"featureType": "poi.park",
				"elementType": "labels.text.fill",
				"stylers": [{
					"color": "#9e9e9e"
				}]
			},
			{
				"featureType": "road",
				"elementType": "geometry",
				"stylers": [{
					"color": "#ffffff"
				}]
			},
			{
				"featureType": "road.arterial",
				"elementType": "labels.text.fill",
				"stylers": [{
					"color": "#757575"
				}]
			},
			{
				"featureType": "road.highway",
				"elementType": "geometry",
				"stylers": [{
					"color": "#dadada"
				}]
			},
			{
				"featureType": "road.highway",
				"elementType": "labels.text.fill",
				"stylers": [{
					"color": "#616161"
				}]
			},
			{
				"featureType": "road.local",
				"elementType": "labels.text.fill",
				"stylers": [{
					"color": "#9e9e9e"
				}]
			},
			{
				"featureType": "transit.line",
				"elementType": "geometry",
				"stylers": [{
					"color": "#e5e5e5"
				}]
			},
			{
				"featureType": "transit.station",
				"elementType": "geometry",
				"stylers": [{
					"color": "#eeeeee"
				}]
			},
			{
				"featureType": "water",
				"elementType": "geometry",
				"stylers": [{
					"color": "#c9c9c9"
				}]
			},
			{
				"featureType": "water",
				"elementType": "labels.text.fill",
				"stylers": [{
					"color": "#9e9e9e"
				}]
			}
		];

		const icons = [
			{
				url: '/skin/frontend/tiptopolmodern/default/images/maps/marker-1.png',
				h: 60,
				w: 40
			},
			{
				url: '/skin/frontend/tiptopolmodern/default/images/maps/marker-2.svg',
				h: 20,
				w: 20
			}
		];

		const maps = [
			{
				id: 'headquarters-map',
				center: {
					lat: 52.4732643,
					lng: 17.2855046
				}, // Pobiedziska 52.4732643,17.2855046
				zoom: 12,

				markers: [{
					position: {
						lat: 52.4732643,
						lng: 17.2855046
					},
					icon: icons[0]
				}]
			},

			// {
			// 	id: 'distributors-map',
			// 	center: { lat: 52.069167, lng: 19.480556 },
			// 	markers: [
			// 		{
			// 			position: {
			// 				lat: 52.069167,
			// 				lng: 19.480556
			// 			},
			// 			icon: icons[1]
			// 		},
			// 		{
			// 			position: {
			// 				lat: 52.4732643,
			// 				lng: 17.2855046
			// 			},
			// 			icon: icons[1]
			// 		}
			// 	]
			// }
		];

		const getSettings = mapEl => {
			return Object.assign({}, mapEl.dataset);
		};

		const getIcon = icon => {

			google = window.google;

			if (icon.hasOwnProperty('scaledSize')) {
				return icon;
			}

			return typeof google !== 'undefined' ? {
				url: icon.url,
				scaledSize: new google.maps.Size(icon.w, icon.h),
				origin: new google.maps.Point(0, 0)
			} : false;
		};

		const getMarker = (marker, map) => {
			google = window.google;

			if (typeof google !== 'undefined') {

				const markerIcon = marker.icon || icons[1];

				const markerOptions = {
					map: map,
					icon: getIcon(markerIcon),
					position: marker.position,
					draggable: false,
					label: typeof marker.label !== 'undefined' ? marker.label : ''
				};

				marker = new google.maps.Marker(markerOptions);

				// google.maps.event.addListener(marker, 'mouseover', function () {
				// 	marker.setIcon(getIcon(icons[1]));
				// });

				// google.maps.event.addListener(marker, 'mouseout', function () {
				// 	marker.setIcon(getIcon(markerIcon));
				// });

				return marker;
			}

			return false;
		};

		const getMarkers = (markers, map) => {
			return Array.isArray(markers) ? markers.map(marker => {
				marker = getMarker(
					marker,
					map
				);
				return marker;
			}) : [];
		};

		const createMapObj = map => {
			const el = document.getElementById(map.id);

			if (!el) {
				return false;
			}

			const settings = getSettings(el);
			map.el = el;

			if (settings.icon) {
				map.markers.map(marker => {
					marker.icon = marker.icon || icons[0];
					marker.icon.url = settings.icon;
					return marker;
				});
			}

			return map;
		};

		const getMaps = maps => {
			return maps.filter(createMapObj);
		};

		const initMap = () => {
			const activeMaps = getMaps(maps);

			activeMaps.map(item => {
				const mapContainer = item.el;

				if (!mapContainer) {
					return;
				}

				const center = item.center;

				const map = new google.maps.Map(mapContainer, {
					zoom: item.zoom || 7,
					center: center,
					styles: mapStyles
				});

				getMarkers(item.markers, map);

				google.maps.event.addDomListener(window, 'resize', () => {
					google.maps.event.trigger(map, "resize");
					map.setCenter(center);
				});
			});
		};

		const loadScript = () => {
			if (typeof google === 'undefined') {
				window.initMap = initMap;
				const API_KEY = 'AIzaSyC2fnyeMzPleS2A5WEFNZBme5Hb9QUDarA';
				var googleMaps = 'https://maps.googleapis.com/maps/api/js?callback=initMap&key=' + API_KEY;
				var script = document.createElement("script");
				script.src = googleMaps;
				document.head.appendChild(script);
			}
		};

		const initMaps = (maps) => {
			if (maps.length) {
				loadScript();
			}
		};

		const init = () => {
			window.addEventListener('load', () => {
				initMaps(getMaps(maps));
			});
		};

		return {
			init
		};

	})();


	maps.init();

})($j, window);
