(($) => {

	"use strict";

	const social = (() => {
		const root = document.getElementById('fb-root');

		const init = () => {
			if (root) {
				$(window).on('load', () => {
					(function (d, s, id) {
						var js, fjs = d.getElementsByTagName(s)[0];
						if (d.getElementById(id)) return;
						js = d.createElement(s); js.id = id;
						js.src = 'https://connect.facebook.net/pl_PL/sdk.js#xfbml=1&version=v2.11&appId=955624011163030';
						fjs.parentNode.insertBefore(js, fjs);
					}(document, 'script', 'facebook-jssdk'));
				});
			}
		};

		return {
			init
		};

	})();

	const distributorsMap = (() => {
		const $appendTo = $('.cms-siec-dystrybucji .col-main .std');
		let $regionList = $appendTo.find('.region-list');
		let activeRegion = $regionList.find('.active').index();
		let $labelList;

		const getMap = () => {
			return $.ajax({
				url: '/skin/frontend/tiptopolmodern/default/images/maps/distributors.svg',
				dataType: 'text'
			});
		};

		const getLabels = $regionList => {
			const newList = $('<ul class="region-labels list-unstyled"></ul>');
			$regionList.find('h2').each((i, item) => {
				$('<li>').text(item.textContent).appendTo(newList);
			});

			return newList;
		};

		const setActive = el => {
			$(el).addClass('active');
			$(el).siblings().removeClass('active');
		};

		const updateRegion = list => {
			$(list).eq(activeRegion).addClass('active');
			$(list).eq(activeRegion).siblings().removeClass('active');
		};

		const addEvents = () => {
			$appendTo.on('click', '.region', function () {
				activeRegion = $(this).index('.region');
				setActive(this);
				updateRegion('.region-list li', 'li');
			});
		};

		const appendMap = map => {
			const $wrapper = $('<div class="regions-map"></div>');
			$wrapper.append(map);
			$appendTo.append($wrapper);
			updateRegion('.regions-map .region');
			$wrapper.append(getLabels($regionList));
			addEvents();
		};

		const init = () => {
			if ($appendTo.length) {
				$labelList = getLabels($regionList);
				getMap(appendMap)
					.then((map => {
						appendMap(map);
					}));
			}
		};

		return {
			init
		};

	})();

	social.init();
	distributorsMap.init('#distributors-map');

})($j);



