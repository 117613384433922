(($) => {

	"use strict";

	const common = (() => {

		const truncateText = (selector) => {
			if (typeof $(selector).dotdotdot === 'function') {
				$(selector).dotdotdot({
					truncate: "word"
				});
			}
		};

		const isMobile = () => {
			const mobileMax = 1024;
			return window.innerWidth <= mobileMax;
		};

		const init = () => {
			const toDot = '.products-grid .product-name a, .article-short, .article-grid .article-title, .skip-account .label';
			$(window).on('load resize', () => {
				truncateText(toDot);
			});
		};

		return {
			init,
			isMobile
		};

	})();

	const arrowBtn = (() => {

		const arrowBtn = document.querySelector('.scroll-down'),
			scrollTarget = document.querySelector('.banner-products'),
			header = document.querySelector('.page-header');

		const getOffset = () => {
			try {
				return scrollTarget.offsetTop - header.offsetHeight + 'px';
			} catch (e) {
				return '650px';
			}
		};

		const scrollDown = () => {
			$('html, body').animate({
				scrollTop: getOffset()
			});
		};

		const addEvents = () => {
			if (arrowBtn) {
				arrowBtn.addEventListener('click', scrollDown);
			}
		};

		const init = () => {
			addEvents();
		};

		return {
			init
		};

	})();

	const productFilters = (() => {

		const $filters = $('.sort-by'),
			$filtersToggle = $filters.find('.button-toggle-filters'),
			activeClass = 'filters-open';

		const toggleFilters = () => {
			$filters.toggleClass(activeClass);
		};

		const addEvents = () => {
			$filtersToggle.on('click', toggleFilters);
		};

		const init = () => {
			addEvents();
		};

		return {
			init
		};

	})();

	const sticky = (() => {

		const parent = '.col-wrapper',
			header = '.page-header',
			offset = 80,
			$sidebar = $('.col-right');

		const getItem = selector => $(selector);

		const allowInViewport = () => window.innerHeight > $sidebar.height();

		const getOffset = () => {
			const $firstBlock = $sidebar.find('.block:first');
			const offsetAdjust = $firstBlock.css('paddingTop') || 0;
			const offset_top = $(header).height() - parseInt(offsetAdjust) + 20 || offset;
			return offset_top;
		};

		const onResize = $stickyItem => {
			if (common.isMobile()) {
				$stickyItem.trigger("sticky_kit:detach");
			} else {
				$stickyItem.trigger("sticky_kit:detach");
				$stickyItem.stick_in_parent({
					offset_top: getOffset(),
					parent: parent
				});
				$(document.body).trigger("sticky_kit:recalc");
			}
		};

		const addEvents = selector => {
			const $stickyItem = getItem(selector);
			if ($stickyItem.length && allowInViewport()) {
				$stickyItem.stick_in_parent({
					offset_top: getOffset(),
					parent: parent
				});
				window.addEventListener('resize', onResize.bind(null, $stickyItem));
			}
		};

		const init = (selector) => {
			addEvents(selector);
		};

		return {
			init
		};

	})();

	const hideButtons = (() => {

		const init = () => {
			const input = $('#newsletter');

			input.on({
				focus: () => {
					$('.block-order').hide();
				},
				blur: () => {
					$('.block-order').show();
				}
			});
		};

		return {
			init
		};

	})();

	const messages = (() => {

		const $messages = $('.messages');

		const closeMessages = () => {
			$messages.fadeOut(500, () => {
				$messages.remove();
			});
		};

		const init = () => {
			if ($messages.length) {
				document.body.addEventListener('click', closeMessages);
			}
		};

		return {
			init
		};

	})();

	const tooltip = (() => {

		const options = {
			arrow: true,
			duration: 400,
			maxWidth: '94%',
			theme: 'tiptopol',
			animation: "shift-away"
		};

		const init = selector => {
			if (selector) {
				tippy(selector, options);
			}
		};

		return {
			init
		};

	})();

	common.init();
	sticky.init('.catalog-product-view .block-order');
	sticky.init('.cms-page-view .block-advice');
	arrowBtn.init();
	productFilters.init();
	hideButtons.init();
	messages.init();
	tooltip.init('.tooltip');

})($j);
