(() => {

	"use strict";

	const quiz = (() => {
		const quizTag = 'tiptopol-quiz';

		const createStyleEl = () => {
			const vqStyleEl = document.createElement('link');
			vqStyleEl.setAttribute('href', '/skin/frontend/tiptopolmodern/default/css/quiz.min.css');
			vqStyleEl.setAttribute('rel', 'stylesheet');
			vqStyleEl.setAttribute('type', 'text/css');

			return vqStyleEl;
		};

		const appendTags = () => {
			requestAnimationFrame(() => {
				document.head.appendChild(createStyleEl());
			});
		};

		const init = () => {
			const vqQuizEl = document.getElementsByTagName(quizTag);
			if (vqQuizEl && vqQuizEl.length) {
				appendTags();
			}
		};

		return {
			init
		};

	})();

	quiz.init();

})();
